import React from 'react'

const About = () => {
  return (
    <div className='about'>
      <p>The Dive Buddy is an app to keep dive logs and create loadouts to use as gear checklists before a dive trip.</p>
        
      </div>
  )
}

export default About